import { CopyrightOutlined } from '@ant-design/icons';

const links = [
  {
    key: 'help',
    title: 'Help',
    href: '',
  },
  {
    key: 'privacy',
    title: 'Privacy',
    href: '',
  },
  {
    key: 'terms',
    title: 'Terms',
    href: '',
  },
];

const Footer = () => {
  return (
    <footer className="ai-admin-footer">
      {links && (
        <div className="links">
          {links.map((link) => (
            <a
              key={link.key}
              title={link.key}
              target={link.blankTarget ? '_blank' : '_self'}
              href={link.href}
              rel="noreferrer"
            >
              {link.title}
            </a>
          ))}
        </div>
      )}
      <div className="copyright">
        Copyright <CopyrightOutlined /> {new Date().getFullYear()} AIdentified,
        LLC
      </div>
    </footer>
  );
};

export default Footer;
