import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const { REACT_APP_AUTH0_REDIRECT_URI } = process.env;

const Header = ({ collapsed, toggle, userName }) => {
  const navigate = useNavigate();
  const { logout } = useAuth0();
  console.log(REACT_APP_AUTH0_REDIRECT_URI);
  const onMenuClick = ({ key }) => {
    if (key === 'userinfo') {
      navigate('/account/settings/base');
      return;
    }
    if (key === 'logout') {
      logout({
        returnTo: `${REACT_APP_AUTH0_REDIRECT_URI}/user/login`,
      });
    }
  };

  const menu = (
    <Menu onClick={onMenuClick}>
      <Menu.Item key="userinfo" icon={<SettingOutlined />}>
        Account Settings
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout" icon={<LogoutOutlined />}>
        Sign out
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="ai-admin-header">
      <div className="ai-fold-icon" onClick={toggle}>
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </div>
      <Dropdown overlay={menu} placement="bottomLeft" trigger="click">
        <div className="ai-user">
          <UserOutlined /> <span className="ai-username">{userName}</span>
        </div>
      </Dropdown>
    </div>
  );
};

export default Header;
