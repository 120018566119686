import '../index.scss';

import React, { useCallback, useEffect, useState } from 'react';
import {
  Form,
  Popconfirm,
  Input,
  DatePicker,
  Divider,
  message,
  Spin,
  Modal,
} from 'antd';
import moment from 'moment';

import { requestWithAuth } from '../../services/api';
import CommonTable from '../../components/CommonTable';
import TopSection from '../../components/TopSection';

let params = {};
//let searchQuery = "";

moment.fn.toJSON = function () {
  return this.format('YYYY-MM-DD');
};

const FormItems = ({ formVals, form }) => {
  useEffect(() => {
    form.resetFields();
  }, [formVals, form]);

  return [
    <Form.Item
      name="url"
      label="Video url"
      required
      rules={[
        {
          required: true,
          message: 'Enter Video url',
        },
      ]}
      initialValue={formVals.url}
    >
      <Input placeholder="Video url" />
    </Form.Item>,

    <Form.Item
      name="start_date"
      label="Start date"
      initialValue={
        formVals.start_date ? moment(formVals.start_date) : undefined
      }
      required
      rules={[
        {
          required: true,
          message: 'Enter start date',
        },
      ]}
    >
      <DatePicker placeholder="start_date" style={{ width: '100%' }} />
    </Form.Item>,

    <Form.Item
      name="end_date"
      label="End date"
      initialValue={formVals.end_date ? moment(formVals.end_date) : undefined}
      required
      rules={[
        {
          required: true,
          message: 'Enter end date',
        },
      ]}
    >
      <DatePicker placeholder="End date" style={{ width: '100%' }} />
    </Form.Item>,
  ];
};

const FormModal = ({
  form,
  isUpdate,
  handleUpdate,
  handleCreate,
  isModalLoading,
  onClose,
  recordFormVals,
  error,
}) => {
  const [formVals, setFormVals] = useState({});

  useEffect(() => {
    if (isUpdate) {
      setFormVals({ ...recordFormVals });
    }
  }, [recordFormVals, isUpdate]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const formValues = { ...values };

        if (formValues.start_date)
          formValues.start_date = moment(formValues.start_date);
        else delete formValues.start_date;

        if (formValues.end_date)
          formValues.end_date = moment(formValues.end_date);
        else delete formValues.end_date;

        if (isUpdate) {
          handleUpdate(formVals.id, formValues);
        } else {
          handleCreate(formValues);
        }
      })
      .catch((errorInfo) => {
        if (errorInfo) {
          console.log(errorInfo);
          message.error('Please fill the mandatory fileds');
          return;
        }
      });
  };

  return (
    <Modal
      title={isUpdate ? 'Update Suggested Video' : 'New Suggested Video'}
      visible
      width={800}
      okText={isUpdate ? 'Update' : 'Create'}
      onOk={handleSubmit}
      confirmLoading={isModalLoading}
      onCancel={onClose}
    >
      <Spin size="medium" spinning={isModalLoading}>
        <Form labelCol={{ span: 7 }} wrapperCol={{ span: 13 }} form={form}>
          <FormItems formVals={formVals} form={form} isUpdate={isUpdate} />
          {error && <div className="error-text">{error}</div>}
        </Form>
      </Spin>
    </Modal>
  );
};

const SuggestedVideo = () => {
  const [listPagination, setListPagination] = useState({
    total: 0,
    pageSize: 50,
    current: 1,
  });
  const { pageSize, current } = listPagination;
  const [videoData, setVideoData] = useState([]);
  const [videoCount, setVideoCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [sortInfo, setSortInfo] = useState({
    column: '',
    order: '',
  });
  const { column, order } = sortInfo;
  const [isUpdate, setIsUpdate] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [recordFormVals, setRecordFormVals] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const [form] = Form.useForm();

  const fetchSuggestedVideoData = useCallback(() => {
    setIsLoading(true);
    const newParams = {
      ...params,
      type: 'video',
      page_size: pageSize,
      page: current,
    };
    if (searchQuery) newParams.q = searchQuery;
    requestWithAuth('vault-dashboard-suggestions', 'GET', newParams, null).then(
      (response) => {
        if (response && response.results) {
          setVideoData(response.results);
          setVideoCount(response.count);
          setListPagination((prevState) => ({
            ...prevState,
            total: response.count,
          }));
          setIsLoading(false);
        }
      },
    );
  }, [pageSize, current, searchQuery]);

  useEffect(() => {
    fetchSuggestedVideoData();

    // return () => {
    //   params = {};
    //   setSortInfo({
    //     column: "",
    //     order: "",
    //   });
    //   setListPagination({
    //     current: 1,
    //     pageSize: 50,
    //   });
    //   searchQuery = "";
    //   params = { type: "video" };
    // };
  }, [fetchSuggestedVideoData, column, order]);
  useEffect(() => {
    return () => {
      params = {};
    };
  }, []);

  const handleFormReset = () => {
    params = {};
    setSortInfo({
      column: '',
      order: '',
    });
    setListPagination({
      current: 1,
      pageSize: 50,
    });
    setSearchQuery('');
    params = {};
    //fetchSuggestedVideoData();
  };

  //   useEffect(() => {
  //     if (total === 0) return;
  //     fetchSuggestedVideoData();
  //   }, [fetchSuggestedVideoData, total]);

  const showUpdateForm = (record) => {
    setIsUpdate(true);
    setShowFormModal(true);
    setRecordFormVals({ ...record });
  };

  const toggleFormModal = (flag) => {
    setShowFormModal(flag);
  };

  const handleSearchQuery = (searchValue) => {
    setSearchQuery(searchValue || '');
    setListPagination((prevState) => ({
      ...prevState,
      current: 1,
    }));
  };

  const handleDelete = (recordId, eventId) => {
    setIsLoading(true);
    requestWithAuth(
      'vault-dashboard-suggestions/' + recordId,
      'DELETE',
      null,
      null,
    ).then((response) => {
      if (response && response.code === 200) message.success('Entry deleted');
      setIsLoading(false);
      fetchSuggestedVideoData();
    });
  };

  const handleCreate = (fields) => {
    setError(null);
    setIsModalLoading(true);
    fields.suggestion_type = 'video';
    requestWithAuth('vault-dashboard-suggestions', 'POST', null, fields)
      .then((response) => {
        if (response && response.error) {
          setError(Object.values(response.error).join(', '));
          setIsModalLoading(false);
          return;
        }
        if (response) {
          fetchSuggestedVideoData();
          setShowFormModal(false);
        }

        setIsModalLoading(false);
      })
      .catch((e) => {
        setIsModalLoading(false);
        setShowFormModal(false);
      });
  };

  const handleUpdate = (recordId, fields) => {
    setError(null);
    fields.suggestion_type = 'video';
    setIsModalLoading(true);
    requestWithAuth(
      `vault-dashboard-suggestions/${recordId}`,
      'PUT',
      null,
      fields,
    )
      .then((response) => {
        setIsModalLoading(false);
        if (response && response.error) {
          setError(Object.values(response.error).join(', '));
          return;
        } else if (response) {
          fetchSuggestedVideoData();
          setIsUpdate(false);
          setShowFormModal(false);
        }
      })
      .catch((e) => {
        setIsModalLoading(false);
        setShowFormModal(false);
        setIsUpdate(false);
      });
  };

  const handleDeleteMultiple = () => {
    const body = {
      id_list: selectedIds,
    };
    setIsLoading(true);
    requestWithAuth(
      'vault-dashboard-suggestions/delete-multiple',
      'POST',
      null,
      body,
    ).then((response) => {
      if (response && response.code === 200) message.success('Entry deleted');
      setSelectedIds([]);
      setSelectedRowKeys([]);
      setIsLoading(false);
      fetchSuggestedVideoData();
    });
  };

  const handleTableChange = (pagination, filtersArg, sorter) => {
    setSelectedRowKeys([]);
    if (sorter && sorter.order && sorter.columnKey) {
      params.ordering =
        sorter.order === 'descend' ? '-' + sorter.columnKey : sorter.columnKey;
      setSortInfo({
        column: sorter.columnKey,
        order: sorter.order,
      });
    } else {
      setSortInfo({
        column: '',
        order: '',
      });
    }
    // if (
    //   pagination.pageSize === listPagination.pageSize &&
    //   pagination.current === listPagination.current
    // ) {
    //   fetchSuggestedVideoData();
    // }
    setListPagination({
      ...pagination,
      pageSize: pagination.pageSize,
      current: pagination.current,
    });
  };

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    let selectedIds = [];
    selectedIds = selectedRows.map((obj) => {
      return obj.id;
    });
    setSelectedRowKeys(selectedRowKeys);
    setSelectedIds(selectedIds);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: 'Video Url',
      dataIndex: 'url',
      key: 'url',
      width: 200,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'url' && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: 'Start Date',
      width: 120,
      dataIndex: 'start_date',
      key: 'start_date',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'start_date' && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val ? moment(val).format('MM/DD/YY') : ''}
          </div>
        );
      },
    },
    {
      title: 'End Date',
      width: 120,
      dataIndex: 'end_date',
      key: 'end_date',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'end_date' && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val ? moment(val).format('MM/DD/YY') : ''}
          </div>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 120,
      onCell: () => ({
        onClick: (e) => {
          e.stopPropagation();
        },
      }),
      render: (text, record) => (
        <>
          <span
            style={{ color: 'var(--color-primary)' }}
            onClick={() => showUpdateForm(record)}
          >
            Edit
          </span>
          <Divider type="vertical" />
          <Popconfirm title="Delete?" onConfirm={() => handleDelete(record.id)}>
            <span
              style={{ color: 'var(--color-primary)' }}
              onClick={(e) => e.preventDefault()}
            >
              Delete
            </span>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="people-editor">
        <TopSection
          count={videoCount}
          placeholder="Search Video url "
          handleSearchQuery={handleSearchQuery}
          handleFormReset={handleFormReset}
          toggleCreateForm={toggleFormModal}
          selectedRowKeys={selectedRowKeys}
          handleDeleteMultiple={handleDeleteMultiple}
          showSearch
          searchValue={searchQuery}
        />
        <Spin size="medium" spinning={isLoading}>
          <CommonTable
            columns={columns}
            data={videoData}
            count={videoCount}
            // components={components}
            rowClassName={() => 'editable-row'}
            onTableChange={handleTableChange}
            pagination={{ ...listPagination }}
            pageSize={listPagination.pageSize}
            loading={isLoading}
            rowKey={(rec) => `${rec.events_id}${rec.id}`}
            rowSelection={rowSelection}
            selectedRowKeys={selectedRowKeys}
            rowEventHandlers={showUpdateForm}
          />
        </Spin>
      </div>
      {showFormModal && (
        <FormModal
          form={form}
          isUpdate={isUpdate}
          handleUpdate={handleUpdate}
          handleCreate={handleCreate}
          isModalLoading={isModalLoading}
          recordFormVals={recordFormVals}
          error={error}
          onClose={() => {
            form.resetFields();
            setIsUpdate(false);
            setShowFormModal(false);
            setError(null);
          }}
        />
      )}
    </>
  );
};

export default SuggestedVideo;
