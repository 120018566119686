import axios from 'axios';
import { message } from 'antd';

message.config({
  top: 80,
});

const { REACT_APP_COREDATA_API_URL } = process.env;

// const responseError = (error) => {
//   console.log('responseError', error);
//   if (error.status === 401) {
//     // TODO
//     // logout from app
//   }
// };

// const serverError = (error) => {
//   console.log('serverError', error.message);
//   console.log(error.config);
// };

export async function requestLookupData(url) {
  // Remove the Authorization header to avoid CORS problems
  const axiosCopy = axios.create();
  delete axiosCopy.defaults.headers.common.Authorization;
  return axiosCopy
    .request({
      url,
      method: 'GET',
    })
    .then((response) => {
      return response.data;
    });
}

export async function updateUserInfo(id, body, progress) {
  const url = 'user-type';
  const absoluteUrl = `${REACT_APP_COREDATA_API_URL}${url}/${id}`;
  return axios
    .request({
      url: absoluteUrl,
      method: 'PATCH',
      data: {
        ...body,
      },
      // cancelToken,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      if (error && error.message === 'Operation canceled by the user.') {
        console.log('Request canceled', error.message);
      } else {
        if (document.getElementById(progress) !== null)
          document.getElementById(progress).style.display = 'none';
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // responseError(error.response)
          console.log(error.response);
          if (
            error.response &&
            error.response.data &&
            error.response.data.detail
          )
            message.error(error.response.data.detail, 10);
          else if (
            error.response &&
            error.response.data &&
            error.response.data.error_description
          )
            message.error(error.response.data.error_description, 10);
          else if (
            error.response &&
            error.response.data &&
            error.response.data.errorCode
          )
            message.error(error.response.data.errorCode, 10);
          else if (
            error.response &&
            error.response.data &&
            error.response.data.message
          )
            message.error(error.response.data.message, 10);
          // return error.response;
          // message.error(error.response);
        } else {
          // serverError(error);
          console.log(error);
          // return error;
          message.error('Network request failed. Please try again later.', 5);
        }
        return null;
      }
    });
}

export async function requestWithAuth(url, method, params, body, cancelToken) {
  const absoluteUrl = `${REACT_APP_COREDATA_API_URL}${url}`;
  return axios
    .request({
      url: absoluteUrl,
      method,
      params: { ...params },
      data: body,
      cancelToken,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const { response = {} } = error;
      if (response.status === 400 && response.data) {
        return { error: response.data };
      } else if (method !== 'GET') {
        message.error('Something went wrong.Please try after sometime.');
      }
    });
}
