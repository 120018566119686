import './layout.scss';

import { Layout } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import axios from 'axios';

import SiderComponent from '../components/SiderComponent';
import Exception from '../pages/Exception';

const { Content } = Layout;

const { REACT_APP_AUTH0_REDIRECT_URI } = process.env;

const nexisAdminAllowedPaths = [
  '/user-editor/users',
  '/user-editor/credits-log',
  '/referrercode',
  '/team-editor',
  '/import-export/contact-exports',
  '/import-export/contact-imports',
  '/reports',
  '/account/settings/base',
  '/',
];
const BasicLayout = ({ userName, userType, isNexisAdmin }) => {
  const { isAuthenticated, getIdTokenClaims, logout } = useAuth0();
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const logoutFromApp = () => {
      logout({
        returnTo: `${REACT_APP_AUTH0_REDIRECT_URI}/user/login`,
      });
    };
    const interceptors = axios.interceptors.request.use(
      (config) => {
        const requestConfig = config;
        if (isAuthenticated) {
          getIdTokenClaims()
            .then((token) => {
              if (!token || !token.__raw) {
                logoutFromApp();
                return;
              }
              if (
                requestConfig &&
                requestConfig.headers &&
                requestConfig.headers.common &&
                requestConfig.headers.common.Authorization
              ) {
                requestConfig.headers.common.Authorization = `Bearer ${token.__raw}`;
              } else if (
                requestConfig &&
                requestConfig.headers &&
                requestConfig.headers.Authorization
              ) {
                requestConfig.headers.Authorization = `Bearer ${token.__raw}`;
              }
            })
            .catch((e) => {
              console.log(e);
              logoutFromApp();
            });
        } else {
          logoutFromApp();
        }
        return requestConfig;
      },
      (error) => {
        console.log(error);
      },
    );
    return () => {
      // Clean up the interceptor when the component unmounts
      axios.interceptors.request.eject(interceptors);
    };
  }, [isAuthenticated, getIdTokenClaims, logout]);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const renderContent = () => {
    if (userType !== 'admin' && !isNexisAdmin)
      return (
        <Content className="usertype-warning">
          <div>Sorry, You don&apos;t have admin access!</div>
        </Content>
      );

    if (isNexisAdmin && !nexisAdminAllowedPaths.includes(location.pathname))
      return <Exception />;

    return (
      <Content
        className="site-layout-background"
        style={{
          margin: '88px 16px 24px 16px',
          padding: 24,
          minHeight: 280,
          backgroundColor: 'var(--color-white)',
        }}
      >
        <Outlet />
      </Content>
    );
  };

  if (!isAuthenticated) return <Navigate to={'/user/login'} />;

  return (
    <Layout>
      <SiderComponent
        collapsible={true}
        collapsed={collapsed}
        isNexisAdmin={isNexisAdmin}
      />
      <Layout className="site-layout">
        <div
          style={{
            position: 'fixed',
            zIndex: 5,
            width: collapsed ? 'calc(100% - 80px)' : 'calc(100% - 256px)',
          }}
        >
          <Header collapsed={collapsed} toggle={toggle} userName={userName} />
        </div>
        {renderContent()}

        <Footer />
      </Layout>
    </Layout>
  );
};

export default BasicLayout;
