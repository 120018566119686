import './layout.scss';

import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Spin } from 'antd';
import logo from '../assets/icons/ai-logo.svg';
import logotype from '../assets/icons/ai-admin-logotype.svg';
import Footer from '../layouts/Footer';

const UserLayout = () => {
  const { isLoading } = useAuth0();

  if (isLoading) return <Spin />;

  return (
    <div className="user-layout">
      <div className="content">
        <div className="header">
          {/* <Link to="/"> */}
          <img className="logo" src={logo} alt="logo" />
          <img className="logotype" src={logotype} alt="logotype" />
          {/* </Link> */}
        </div>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default UserLayout;
