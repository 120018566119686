// import { Provider } from 'mobx-react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { Auth0Provider } from '@auth0/auth0-react';

import App from './App';
// import DataStore from './stores/DataStore';
// import GlobalDataStore from './stores/GlobalDataStore';
// import ModalDataStore from './stores/ModalDataStore';
import reportWebVitals from './reportWebVitals';

const {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_REDIRECT_URI,
} = process.env;

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Auth0Provider
    domain={REACT_APP_AUTH0_DOMAIN}
    clientId={REACT_APP_AUTH0_CLIENT_ID}
    redirectUri={`${REACT_APP_AUTH0_REDIRECT_URI}/user/login/callback`}
    forgotPasswordLink={`${REACT_APP_AUTH0_REDIRECT_URI}/user/forgot-password`}
    useRefreshTokens={true}
    cacheLocation="localstorage"
  >
    {/* <Provider
      globalDataStore={new GlobalDataStore()}
      dataStore={new DataStore()}
      modalDataStore={new ModalDataStore()}
    > */}
    {/* <React.StrictMode> */}
    <App />
    {/* </React.StrictMode> */}
    {/* </Provider> */}
  </Auth0Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
