import React, { PureComponent } from 'react';
import { Select } from 'antd';
import axios from 'axios';
import { requestWithAuth } from '../../services/api';

const { Option } = Select;
let source;
class DataSearch extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
    };
  }

  handleSearch = (value) => {
    if (value) {
      this.setState({ loading: true });
      const params = {
        is_user_map: 1,
        q: value,
      };
      const { id } = this.props;

      // if (controller) controller.abort();
      // controller = new AbortController(); // create a controller
      // const { signal } = controller; // grab a reference to its associated AbortSignal object using the AbortController.signal property

      if (source) source.cancel('Operation canceled by the user.');
      const { CancelToken } = axios;
      source = CancelToken.source();

      setTimeout(() => {
        requestWithAuth(id, 'GET', params, null, source.token)
          .then((response) => {
            if (response && response.results) {
              this.setState({ data: response.results, loading: false });
            } else {
              this.setState({ data: [], loading: false });
            }
          })
          .catch((e) => {
            this.setState({ data: [], loading: false });
            console.log(e);
          });
      }, 500);
    } else {
      this.setState({ data: [], loading: false });
    }
  };

  handleChange = (value, data) => {
    const { onSelect } = this.props;
    if (onSelect) onSelect(data);
  };

  render() {
    const { data, loading } = this.state;
    return (
      <Select
        style={{ width: '100%' }}
        showSearch
        labelInValue
        loading={loading}
        value={this.props.value}
        placeholder={this.props.placeholder}
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSearch={this.handleSearch}
        onChange={this.handleChange}
        notFoundContent={null}
        // isMulti
        // mode="multiple"
        allowClear
        disabled={this.props.disabled}
      >
        {data &&
          data.length > 0 &&
          data.map((item) => {
            return (
              <Option
                key={item.id}
                first_name={item.first_name}
                last_name={item.last_name}
                email={item.email}
              >
                <div className="select-class">
                  <div>{item.email}</div>
                  <div className="sub-option">
                    {item.first_name || ''} {item.last_name || ''}
                  </div>
                </div>
              </Option>
            );
          })}
      </Select>
    );
  }
}
export default DataSearch;
