import '../index.scss';

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Input, Button, Spin, Modal, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import axios from 'axios';
import { requestWithAuth } from '../../services/api';
import { getValue } from '../../utils/general';
import CommonTable from '../../components/CommonTable';
import TopSection from '../../components/TopSection';

let params = {};
// let searchQuery = "";
let source;

const FormModal = ({ data, onClose }) => {
  return (
    <Modal
      title={'Changes'}
      open
      width={800}
      okText={null}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Back
        </Button>,
      ]}
    >
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </Modal>
  );
};

const ProfileEdits = (props) => {
  const [listPagination, setListPagination] = useState({
    total: 0,
    pageSize: 50,
    current: 1,
  });
  const { pageSize, current } = listPagination;
  const [profileEditorData, setProfileEditorData] = useState([]);
  const [filteredInfoOpType, setFilteredInfoOpType] = useState([]);
  const [profileDataCount, setProfileDataCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [sortInfo, setSortInfo] = useState({
    column: '',
    order: '',
  });
  const [showFormModal, setShowFormModal] = useState(false);
  const [changesData, setChangesData] = useState(null);

  const searchInputRef = useRef(null);
  const { column, order } = sortInfo;

  const fetchProfileData = useCallback(() => {
    setIsLoading(true);
    const newParams = {
      ...params,
      page_size: pageSize,
      page: current,
    };
    // if (searchQuery) newParams.q = searchQuery;

    if (source) source.cancel('Operation canceled by the user.');
    const { CancelToken } = axios;
    source = CancelToken.source();

    requestWithAuth(
      'vault-person-delta',
      'GET',
      newParams,
      null,
      source.token,
    ).then((response) => {
      if (response && response.results) {
        setProfileEditorData(response.results);
        setProfileDataCount(response.count);
        setListPagination((prevState) => ({
          ...prevState,
          total: response.count,
        }));
        setIsLoading(false);
      }
    });
  }, [pageSize, current]);

  useEffect(() => {
    return () => {
      params = {};
      setSortInfo({
        column: '',
        order: '',
      });
      setFilteredInfoOpType([]);
      //   searchQuery = "";
    };
  }, []);

  useEffect(() => {
    fetchProfileData();
  }, [fetchProfileData, filteredInfoOpType, column, order]);

  const handleSearch = (selectedKeys, confirm, dataIndex, clearFilters) => {
    let searchData = selectedKeys[0];
    if (searchData !== undefined) params[dataIndex] = searchData;

    confirm();
  };

  const handleReset = (clearFilters, confirm, dataIndex) => {
    clearFilters();
    delete params[dataIndex];
    fetchProfileData();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputRef}
          placeholder={`Search ${dataIndex}`}
          value={
            // eslint-disable-next-line
            selectedKeys[0] ? selectedKeys[0] : ''
          }
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, dataIndex, clearFilters)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() =>
            handleSearch(selectedKeys, confirm, dataIndex, clearFilters)
          }
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters, confirm, dataIndex)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
      // style={{
      //     color: filtered ? 'var(--color-primary)' : undefined,
      // }}
      />
    ),

    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInputRef.current.select());
      }
    },
  });

  const handleTableChange = (pagination, filtersArg, sorter) => {
    Object.keys(filtersArg).reduce((obj, key) => {
      const filt = { ...obj };
      let value = '';
      if (filtersArg[key] !== null) value = getValue(filtersArg[key]);
      if (key === 'op') {
        if (!value) {
          setFilteredInfoOpType([]);
          delete params.op;
        } else {
          filt.op = value.split('\n').join(',');
          setFilteredInfoOpType(value.split(','));
          params.op = value.split('\n').join(',');
        }
      }
      return null;
    }, {});
    if (sorter && sorter.order && sorter.columnKey) {
      params.ordering =
        sorter.order === 'descend' ? '-' + sorter.columnKey : sorter.columnKey;
      setSortInfo({
        column: sorter.columnKey,
        order: sorter.order,
      });
    } else {
      setSortInfo({
        column: '',
        order: '',
      });
      delete params.ordering;
    }
    // setParams(params);
    // if (
    //   pagination.pageSize === listPagination.pageSize &&
    //   pagination.current === listPagination.current
    // ) {
    //   fetchProfileData();
    // }
    setListPagination({
      ...pagination,
      pageSize: pagination.pageSize,
      current: pagination.current,
    });
  };

  const toggleFormModal = (flag) => {
    setShowFormModal(flag);
  };

  //   const handleSearchQuery = (searchValue) => {
  //     searchQuery = searchValue;
  //     if (searchQuery) fetchProfileData();
  //   };

  const handleFormReset = () => {
    params = {};
    setSortInfo({
      column: '',
      order: '',
    });
    setListPagination({
      current: 1,
      pageSize: 50,
    });
    setFilteredInfoOpType([]);
    // searchQuery = "";
    //fetchProfileData();
  };

  const showModal = (record) => {
    if (record && record.changes) {
      setShowFormModal(true);
      setChangesData(record.changes);
    } else {
      message.info('No changes for this profile', 10);
    }
  };

  const columns = [
    {
      title: 'Creation Date',
      width: 120,
      dataIndex: 'created',
      key: 'created',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'created' && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val ? moment(val).format('MM/DD/YY') : ''}
          </div>
        );
      },
    },
    {
      title: 'b2b_id',
      width: 250,
      dataIndex: 'b2b_id',
      key: 'b2b_id',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'b2b_id' && sortInfo.order,
      ...getColumnSearchProps('b2b_id'),
      render: (_val, rec) => {
        return (
          <div style={{ width: '100%', hyphens: 'none' }}>{rec.b2b_id}</div>
        );
      },
    },
    {
      title: 'b2c_id',
      width: 150,
      dataIndex: 'b2c_id',
      key: 'b2c_id',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'b2c_id' && sortInfo.order,
      ...getColumnSearchProps('b2c_id'),
      render: (_val, rec) => {
        return (
          <div style={{ width: '100%', hyphens: 'none' }}>{rec.b2c_id}</div>
        );
      },
    },
    {
      title: 'Full Name',
      width: 150,
      dataIndex: 'full_name',
      key: 'full_name',
      render: (_val, rec) => {
        return (
          <div style={{ width: '100%', wordBreak: 'break-word' }}>
            {rec.full_name}
          </div>
        );
      },
    },
    {
      title: 'Operations',
      width: 150,
      dataIndex: 'op',
      key: 'op',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'op' && sortInfo.order,
      filters: [
        { text: 'DELETE', value: 'delete' },
        { text: 'INSERT', value: 'insert' },
        { text: 'UPDATE', value: 'update' },
      ],
      filterMultiple: false,
      filteredValue: filteredInfoOpType || [],
      render: (_val, rec) => {
        return (
          <div
            style={{
              width: '100%',
              wordBreak: 'break-word',
              textTransform: 'uppercase',
            }}
          >
            {rec.op}
          </div>
        );
      },
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      key: 'owner',
      width: 160,
      render: (val, rec) => {
        return <div className="column-style">{val.name}</div>;
      },
    },
  ];

  return (
    <>
      <div className="people-editor">
        <TopSection
          count={profileDataCount}
          placeholder="Search names, companies, LinkedIn, IDs, phones, or emails"
          //   handleSearchQuery={handleSearchQuery}
          handleFormReset={handleFormReset}
          toggleCreateForm={toggleFormModal}
          //   showSearch
          showNewButton={false}
        />
        <Spin size="medium" spinning={isLoading}>
          <CommonTable
            columns={columns}
            data={profileEditorData}
            rowClassName={() => 'editable-row'}
            onTableChange={handleTableChange}
            pagination={{ ...listPagination }}
            pageSize={listPagination.pageSize}
            loading={isLoading}
            rowEventHandlers={showModal}
          />
        </Spin>
      </div>
      {showFormModal && (
        <FormModal
          data={changesData}
          onClose={() => {
            setShowFormModal(false);
          }}
        />
      )}
    </>
  );
};

export default ProfileEdits;
