import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import axios from 'axios';
import { requestWithAuth } from '../../services/api';

let source;
const CreatableMultiSelect = ({
  id,
  isMulti,
  placeholder,
  isClearable = true,
  onSelect,
  value,
}) => {
  const [options, setOptions] = useState([]);

  function handleInputChange(value) {
    const options = [];
    if (value.length > 0) {
      if (source) source.cancel('Operation canceled by the user.');
      const { CancelToken } = axios;
      source = CancelToken.source();
      const params = {};
      let type = 'n2';
      params.lookup_type = type;
      params.search_key =
        id === 'org_name' ? 'ai_org' : id === 'exc_title' ? 'title' : id;
      params.search_value = value;
      params.filter_type = 'all';
      requestWithAuth('auto-suggest-new', 'GET', params, null, source.token)
        .then((response) => {
          if (response && response.result) {
            response.result.map((data) => {
              if (data)
                switch (id) {
                  case 'org_name':
                    options.push({
                      value: data.ai_org_id,
                      label: data.name
                        ? data.name.toString().toTitleCase()
                        : '',
                      labelValue: data.name,
                    });
                    break;
                  default:
                    options.push({
                      value: data,
                      label: data.toString().toTitleCase(),
                    });
                    break;
                }
              return '';
            });
          }
          setOptions(options);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function debounce(func, delay) {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  }

  function handleChange(selected) {
    if (onSelect) {
      onSelect(selected);
    }
  }

  return (
    <CreatableSelect
      formatCreateLabel={(inputValue) => `Contains "${inputValue}"`}
      isValidNewOption={(inputValue) => {
        if (inputValue && inputValue !== '') return true;
      }}
      placeholder={placeholder}
      value={value}
      isMulti={isMulti}
      className="multi-auto-suggest"
      classNamePrefix="ai"
      isSearchable
      isClearable={isClearable}
      hideSelectedOptions={true} // FIX: issue with 2 compnies with same id on selected gets both cleared
      cropWithEllipsis
      closeMenuOnSelect={false}
      closeMenuOnScroll
      // onFocus={this.inFocus}
      onChange={handleChange}
      onInputChange={debounce(handleInputChange, 500)}
      options={options}
      autoFocus
      onBlur={() => {
        setOptions([]);
      }}
      createOptionPosition="first"
      noOptionsMessage={() => null}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
    />
  );
};
export default CreatableMultiSelect;
